const metaZulu = {
    homePageTitle: "AUTO24.co.za - Gesertifiseerde Gebruikte Motors in Suid-Afrika | Koop & Verkoop Plaaslik",
    homePageDesc: "Soek jy 'n gesertifiseerde gebruikte motor in Suid-Afrika? AUTO24.co.za is jou plaaslike mark vir die koop en verkoop van kwaliteit gebruikte motors.",
    homePageKeywords: "Gesertifiseerde Gebruikte Motors, Koop Motors Suid-Afrika, Verkoop Motors, Motor Mark, AUTO24 Suid-Afrika",
    homePageTitleOg: "AUTO24.co.za - Gesertifiseerde Gebruikte Motors in Suid-Afrika | Koop & Verkoop Plaaslik",
    homePageDescOg: "Soek jy 'n gesertifiseerde gebruikte motor in Suid-Afrika? AUTO24.co.za is jou plaaslike mark vir die koop en verkoop van kwaliteit gebruikte motors.",
    homePageKeywordsOg: "Gesertifiseerde Gebruikte Motors, Koop Motors Suid-Afrika, Verkoop Motors, Motor Mark, AUTO24 Suid-Afrika",
    buyPageTitle: "Koop Gesertifiseerde Kwaliteit Gebruikte Motors in Suid-Afrika | AUTO24.co.za",
    buyPageDesc: "Verken 'n wye reeks gesertifiseerde gebruikte motors by AUTO24.co.za. Vind jou ideale motor maklik en vinnig.",
    buyCarKeywords: "Koop Motors, Gesertifiseerde Gebruikte Motors, Kwaliteit Motors, Bekostigbare Motors",
    buyPageTitleOg: "Koop Gesertifiseerde Kwaliteit Gebruikte Motors in Suid-Afrika | AUTO24.co.za",
    buyPageDescOg: "Verken 'n wye reeks gesertifiseerde gebruikte motors by AUTO24.co.za. Vind jou ideale motor maklik en vinnig.",
    buyCarKeywordsOg: "Koop Motors, Gesertifiseerde Gebruikte Motors, Kwaliteit Motors, Bekostigbare Motors",
    sellPageTitle: "Verkoop Jou Motor Vinnig & Gemaklik in Suid-Afrika | AUTO24.co.za",
    sellPageDesc: "Wil jy jou motor verkoop? Ons bied 'n moeitevrye manier om dit te doen, met gratis evaluasies en vinnige verkope.",
    sellPageKeywords: "Verkoop Motors, Gebruikte Motor Verkope, Vinnige Verkope, Motor Evaluering",
    sellPageTitleOg: "Verkoop Jou Motor Vinnig & Gemaklik in Suid-Afrika | AUTO24.co.za",
    sellPageDescOg: "Wil jy jou motor verkoop? Ons bied 'n moeitevrye manier om dit te doen, met gratis evaluasies en vinnige verkope.",
    sellPageKeywordOg: "Verkoop Motors, Gebruikte Motor Verkope, Vinnige Verkope, Motor Evaluering",
    aboutPageTitle: "Oor AUTO24.co.za - Jou Betroubare Plaaslike Gebruikte Motor Mark",
    aboutPageDesc: "Leer meer oor AUTO24.co.za, Suid-Afrika se voorste gesertifiseerde gebruikte motor mark.",
    aboutPageKeywords: "Oor AUTO24, Gesertifiseerde Gebruikte Motor Mark, Betroubare Motor Verkope",
    aboutPageTitleOg: "Oor AUTO24.co.za - Jou Betroubare Plaaslike Gebruikte Motor Mark",
    aboutPageDescOg: "Leer meer oor AUTO24.co.za, Suid-Afrika se voorste gesertifiseerde gebruikte motor mark.",
    aboutPageKeywordsOg: "Oor AUTO24, Gesertifiseerde Gebruikte Motor Mark, Betroubare Motor Verkope",
    financePageTitle: "Finansier Jou Motor Maklik met AUTO24.co.za",
    financePageDesc: "Kry die finansiering wat jy nodig het vir jou volgende motor deur AUTO24.co.za. Eenvoudige en reguit motorfinansiering opsies.",
    financePageKeywords: "Motor Finansiering, AUTO24 Finansiering, Maklike Finansiering",
    financePageTitleOg: "Finansier Jou Motor Maklik met AUTO24.co.za",
    financePageDescOg: "Kry die finansiering wat jy nodig het vir jou volgende motor deur AUTO24.co.za. Eenvoudige en reguit motorfinansiering opsies.",
    financePageKeywordOg: "Motor Finansiering, AUTO24 Finansiering, Maklike Finansiering",
    insurePageTitle: "Verseker Jou Motor met Vrymoedigheid deur AUTO24.co.za",
    insurePageDesc: "Kry jou motor verseker met gemoedsrus. Ons bied 'n verskeidenheid van versekering opsies om aan jou behoeftes te voldoen.",
    insurePageKeywords: "Motor Versekering, AUTO24 Versekering, Voertuig Dekking",
    insurePageTitleOg: "Verseker Jou Motor met Vrymoedigheid deur AUTO24.co.za",
    insurePageDescOg: "Kry jou motor verseker met gemoedsrus. Ons bied 'n verskeidenheid van versekering opsies om aan jou behoeftes te voldoen.",
    insurePageKeywordOg: "Motor Versekering, AUTO24 Versekering, Voertuig Dekking",
    blogPageTitle: "AUTO24.co.za Blog - Insigte & Wenke oor die Suid-Afrikaanse Motor Mark",
    blogPageDesc: "Bly op datum met die nuutste tendense, wenke en nuus in die Suid-Afrikaanse motor mark.",
    blogPageKeywords: "AUTO24 Blog, Motor Wenke, Motor Nuus, Suid-Afrikaanse Motor Mark",
    blogPageTitleOg: "AUTO24.co.za Blog - Insigte & Wenke oor die Suid-Afrikaanse Motor Mark",
    blogPageDescOg: "Bly op datum met die nuutste tendense, wenke en nuus in die Suid-Afrikaanse motor mark.",
    blogPageKeywordsOg: "AUTO24 Blog, Motor Wenke, Motor Nuus, Suid-Afrikaanse Motor Mark",
    qNaPageTitle: "AUTO24 - Frequently asked questions ",
    contactUsPageTitle: " AUTO24 - Contact us",
    privacyPolicyPageTitle: " AUTO24 - Privacy policy",
    termsNusePageTitle: "AUTO24 - Terms & conditions",
    carDetailPageTitle: "Buy {{make}},{{price}} R - AUTO24",
    carDetailPageDesc: "Buy a {{make}} certified used car at {{PRIX}} R with AUTO24, South Africa",
    buyPageDescWhenBrand: "Best prices and services for {{BRAND}} used cars in South Africa. Refund policy, warranty, insurance, maintenance, and all included!",
    buyCarTitle: "Koop Gesertifiseerde Kwaliteit Gebruikte Motors in Suid-Afrika | AUTO24.co.za",
    buyCarDesc: "Verken 'n wye reeks gesertifiseerde gebruikte motors by AUTO24.co.za. Vind jou ideale motor maklik en vinnig."
}

export default metaZulu;