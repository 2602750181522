import { IFilteredLang } from './interface/generalInterface';

export const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api`;
export const IMAGE_URL = `${process.env.REACT_APP_BASE_URL}/`;
export const googleSignInLink = 'https://mail.google.com/mail/u/0/#inbox';

export const getCars = 'cars';
export const getCarsByUid = 'cars/by-uid';
export const footerContent = 'footer-content';
export const getWishList = 'wishlists';
export const getservicehistories = 'service-histories';
export const serviceHistoryByCarIds = 'service-histories/by-car';
export const getfaqs = 'faqs';
export const carCertification = 'car-certifications';
export const carBrands = 'car-brands';
export const carAvailableBrands = 'car-brands/by/car';
export const sellingCars = 'selling-cars';
export const modelByBrands = 'car-models/by-brand';
export const contactQuery = 'contact-queries';
export const contactInformation = 'contact-information';
export const pages = 'pages';
export const financePartner = 'financing-partners';
export const subscription = 'subscribers';
export const instagramUrl = 'contact-information/get-instagram-url';
export const filteredCarsFooter = 'car-models/filter';

export type $FIXME = any;

export const whatsappNumber = '212663311575';

// export const whatsappUrl = 'https://wa.me/212663311575';
export const whatsappUrl = 'https://wa.me/';
export const faceBooKLink = 'https://www.facebook.com/auto24africa';
export const instagramLink = 'https://www.instagram.com/auto24africa';
export const linkedInLink = 'https://www.linkedin.com/company/auto24africa';
export const tiktokLink =
    'https://www.tiktok.com/@auto24africa?_t=8V9nZUUpo1u&_r=1';
export const emailRegs =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegs = /^(?:\+?)(?:[0-9]{1,3}\ ?)([0-9]{9})$/;

export const numberRegx = /^[0-9]+$/;
export const carPageUrl = `${process.env.REACT_APP_FRONT_END_URL}/car`;
export const blogPageUrl = `${process.env.REACT_APP_FRONT_END_URL}/blog`;
export const sessionExpireDate = 13;

export const CSWIPER = {
    startOfSlider: 0,
    slidesPerView: 4,
    slidesToSkip: 5,
    maxCardSliderWidth: 1280
};

// Add the possible country top level domain in websites.
// Need to add here if you want to add new country domain
export const COUNTRY_TOP_LEVEL_DOMAIN: { [key: string]: string } = {
    ma: 'ma',
    coza: 'co.za',
    rw: 'rw',
    ci: 'ci',
    sn: 'sn',
    tg: 'tg',
    asterdio: 'asterdio.xyz'
} as const;

// All the languages
// Need to add here if you want to add new language
export const ALL_LANGUAGES: { [key: string]: IFilteredLang } = {
    english: {
        label: 'English',
        value: 'en',
        code: 'US',
        includedInDomain: [
            COUNTRY_TOP_LEVEL_DOMAIN.ma,
            COUNTRY_TOP_LEVEL_DOMAIN.ci,
            COUNTRY_TOP_LEVEL_DOMAIN.coza,
            COUNTRY_TOP_LEVEL_DOMAIN.rw,
            COUNTRY_TOP_LEVEL_DOMAIN.tg,
            COUNTRY_TOP_LEVEL_DOMAIN.sn,
            COUNTRY_TOP_LEVEL_DOMAIN.asterdio
        ]
    },
    french: {
        label: 'French',
        value: 'fr',
        code: 'FR',
        includedInDomain: [
            COUNTRY_TOP_LEVEL_DOMAIN.ma,
            COUNTRY_TOP_LEVEL_DOMAIN.ci,
            COUNTRY_TOP_LEVEL_DOMAIN.rw,
            COUNTRY_TOP_LEVEL_DOMAIN.tg,
            COUNTRY_TOP_LEVEL_DOMAIN.sn,
            COUNTRY_TOP_LEVEL_DOMAIN.asterdio
        ]
    },
    arabic: {
        label: 'Arabic',
        value: 'ar',
        code: 'MA',
        includedInDomain: [COUNTRY_TOP_LEVEL_DOMAIN.ma]
    },
    kinyarnwanda: {
        label: 'Kinyarnwanda',
        value: 'rw',
        code: 'RW',
        includedInDomain: [
            COUNTRY_TOP_LEVEL_DOMAIN.rw,
            COUNTRY_TOP_LEVEL_DOMAIN.asterdio
        ]
    },
    zulu: {
        label: 'Zulu',
        value: 'zu',
        code: 'ZA',
        includedInDomain: [COUNTRY_TOP_LEVEL_DOMAIN.coza]
    },
    xhosa: {
        label: 'Xhosa',
        value: 'xh',
        code: 'ZA',
        includedInDomain: [COUNTRY_TOP_LEVEL_DOMAIN.coza]
    },
    wolof: {
        label: 'Wolof',
        value: 'sn',
        code: 'SN',
        includedInDomain: [COUNTRY_TOP_LEVEL_DOMAIN.sn]
    }
};

// Filter language on the basis of top level domain name.
export const FILTERED_LANG = Object.values(ALL_LANGUAGES);

// Routes List
export const ROUTES = {
    home: '/home',
    slash: '/',
    buyCars: '/buy-cars',
    buy: '/buy',
    sellCar: '/sell-car',
    finance: '/finance',
    carInsurance: '/car-insurance',
    about: '/about',
    faqs: '/faqs',
    contactUs: '/contact-us',
    blog: '/blog',
    account: '/account',
    imInterested: '/interested',

    category: '/category',

    privacyPolicy: '/privacy-policy',
    termsOfUse: '/terms-of-use',
    unsubscribeBlog: '/unsubscribe-blog',
    // forgotPassword : "/forgot-password"

    editProfile: '/edit-profile',
    sellYourCar: '/sell-your-car',
    resetPassword: '/reset-password',
    favourites: 'favourites',
    ourTeam: 'our-team',
    findCarPrice: '/pricing-tool'
};
