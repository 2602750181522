import {
    CAR_LIST_ERROR,
    CAR_LIST_LOADING,
    CAR_LIST_SUCCESS,
    GET_CARS_DETAILS
} from '../types/carListTypes';

const initialState = {
    loading: false,
    error: false,
    cars: [],
    car: {},
    similarCars: [],
    totalCar: 0
};
const carListingReducer = (state: any = initialState, data: any) => {
    switch (data.type) {
        case CAR_LIST_LOADING:
            return {
                ...state,
                loading: true
            };
        case CAR_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                cars: data.payload.cars,
                totalCar: data.payload.total
            };

        case CAR_LIST_ERROR:
            return {
                ...state,
                loading: false,
                cars: [],
                error: true
            };
        case GET_CARS_DETAILS:
            return {
                ...state,
                loading: false,
                car: data.payload.car,
                similarCars: data.payload.similarCars
            };
        default:
            return state;
    }
};
export default carListingReducer;
