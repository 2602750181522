import { COUNTRY_TOP_LEVEL_DOMAIN } from '../../constants';

const getMetaEnglish = (topLevelDomain: string) => {
    switch (topLevelDomain) {
        case COUNTRY_TOP_LEVEL_DOMAIN.ma:
            return {
                homePageTitle:
                    'AUTO24.ma - Certified Used Cars in Morocco | Buy & Sell Cars Nationwide',
                homePageDesc:
                    'Your go-to marketplace in Morocco for buying and selling certified used cars. Enjoy free evaluations, quick sales, and quality assurance across the nation.',
                homePageKeywords:
                    'Certified Used Cars, Buy Used Cars Morocco, Sell Used Cars Morocco, Car Marketplace, AUTO24 Morocco, Quality Used Cars, Auto Sales Morocco',
                homePageTitleOg:
                    'AUTO24.ma - Certified Used Cars in Morocco | Buy & Sell Cars Nationwide',
                homePageDescOg:
                    'Your go-to marketplace in Morocco for buying and selling certified used cars. Enjoy free evaluations, quick sales, and quality assurance across the nation.',
                homePageKeywordsOg:
                    'Certified Used Cars, Buy Used Cars Morocco, Sell Used Cars Morocco, Car Marketplace, AUTO24 Morocco, Quality Used Cars, Auto Sales Morocco',
                buyPageTitle:
                    'Buy Certified Quality Used Cars in Morocco | AUTO24.ma',
                buyPageDesc:
                    'Explore a wide selection of certified used cars at AUTO24.ma . We simplify your search for the perfect car that fits your budget and lifestyle across Morocco.',
                buyPageKeywords:
                    'Buy Cars Morocco, Certified Used Cars, AUTO24 Morocco, Car Shopping, Affordable Cars Morocco',
                buyPageTitleOg:
                    'Buy Certified Quality Used Cars in Morocco | AUTO24.ma',
                buyPageDescOg:
                    'Explore a wide selection of certified used cars at AUTO24.ma . We simplify your search for the perfect car that fits your budget and lifestyle across Morocco.',
                buyPageKeywordsOg:
                    'Buy Cars Morocco, Certified Used Cars, AUTO24 Morocco, Car Shopping, Affordable Cars Morocco',
                sellPageTitle:
                    'Sell Your Car Fast & Conveniently in Morocco | AUTO24.ma',
                sellPageDesc:
                    'Looking to sell your car? AUTO24.ma offers a hassle-free way to do it, providing free evaluations and quick sales nationwide in Morocco.',
                sellPageKeywords:
                    'Sell Car Morocco, Used Car Sales Morocco, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Morocco',
                sellPageTitleOg:
                    'Sell Your Car Fast & Conveniently in Morocco | AUTO24.ma',
                sellPageDescOg:
                    'Looking to sell your car? AUTO24.ma offers a hassle-free way to do it, providing free evaluations and quick sales nationwide in Morocco.',
                sellPageKeywordsOg:
                    'Sell Car Morocco, Used Car Sales Morocco, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Morocco',
                aboutPageTitle:
                    'About AUTO24.ma - Your Trusted Nationwide Used Car Marketplace in Morocco',
                aboutPageDesc:
                    "Learn about AUTO24.ma , Morocco's premier certified used car marketplace. Offering quality cars and a seamless buying and selling experience across the country.",
                aboutPageKeywords:
                    'About AUTO24 Morocco, Certified Used Car Marketplace, Trusted Car Sales, Morocco Car Dealership',
                aboutPageTitleOg:
                    'About AUTO24.ma - Your Trusted Nationwide Used Car Marketplace in Morocco',
                aboutPageDescOg:
                    "Learn about AUTO24.ma , Morocco's premier certified used car marketplace. Offering quality cars and a seamless buying and selling experience across the country.",
                aboutPageKeywordsOg:
                    'About AUTO24 Morocco, Certified Used Car Marketplace, Trusted Car Sales, Morocco Car Dealership',
                financePageTitle:
                    'Easily Finance Your Used Car with AUTO24.ma in Morocco',
                financePageDesc:
                    'Discover flexible financing options for your used car with AUTO24.ma . Simplify car purchasing across Morocco.',
                financePageKeywords:
                    'Car Financing, Auto Credit, AUTO24 Morocco, Financing Options, Auto Loan',
                financePageTitleOg:
                    'Easily Finance Your Used Car with AUTO24.ma in Morocco',
                financePageDescOg:
                    'Discover flexible financing options for your used car with AUTO24.ma . Simplify car purchasing across Morocco.',
                financePageKeywordsOg:
                    'Car Financing, Auto Credit, AUTO24 Morocco, Financing Options, Auto Loan',
                insurePageTitle:
                    'Secure Reliable Car Insurance with AUTO24.ma in Morocco',
                insurePageDesc:
                    'Obtain affordable, reliable car insurance for your used car with AUTO24.ma . Choose from a range of options to meet your needs nationwide.',
                insurePageKeywords:
                    'Car Insurance, Insurance Coverage, AUTO24 Morocco, Used Car Insurance, Insurance Options',
                insurePageTitleOg:
                    'Secure Reliable Car Insurance with AUTO24.ma in Morocco',
                insurePageDescOg:
                    'Obtain affordable, reliable car insurance for your used car with AUTO24.ma . Choose from a range of options to meet your needs nationwide.',
                insurePageKeywordsOg:
                    'Car Insurance, Insurance Coverage, AUTO24 Morocco, Used Car Insurance, Insurance Options',
                blogPageTitle:
                    'AUTO24.ma Blog - Tips, Tricks, and Industry News in Morocco',
                blogPageDesc:
                    'Stay updated with the latest news, tips, and tricks in the Moroccan auto market. AUTO24.ma is your go-to resource for everything used cars.',
                blogPageKeywords:
                    'AUTO24 Blog, Auto News, Car Tips, Moroccan Auto Market, Car Tricks',
                blogPageTitleOg:
                    'AUTO24.ma Blog - Tips, Tricks, and Industry News in Morocco',
                blogPageDescOg:
                    'Stay updated with the latest news, tips, and tricks in the Moroccan auto market. AUTO24.ma is your go-to resource for everything used cars.',
                blogPageKeywordsOg:
                    'AUTO24 Blog, Auto News, Car Tips, Moroccan Auto Market, Car Tricks',
                qNaPageTitle: 'AUTO24 - Frequently asked questions ',
                contactUsPageTitle: ' AUTO24 - Contact us',
                privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
                termsNusePageTitle: 'AUTO24 - Terms & conditions',
                carDetailPageTitle: 'Buy {{make}},{{price}} DH - AUTO24',
                carDetailPageDesc:
                    'Buy a {{make}} certified used car at {{PRIX}} DH with AUTO24, Morocco',
                buyPageDescWhenBrand:
                    'Best prices and services for {{BRAND}} used cars in Morocco. Refund policy, warranty, insurance, maintenance, and all included!'
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.ci:
            return {
                homePageTitle:
                    "AUTO24.ci - Certified Used Cars in Abidjan, Côte d'Ivoire | Buy & Sell Cars Locally",
                homePageDesc:
                    "Looking for a certified used car in Abidjan, Côte d'Ivoire? AUTO24.ci is your go-to local marketplace for buying and selling quality used cars. Enjoy free evaluations, quick sales, and comprehensive certification.",
                homePageKeywords:
                    "Certified Used Cars, Buy Used Cars Abidjan, Sell Used Cars Côte d'Ivoire, Car Marketplace, AUTO24 Côte d'Ivoire, Quality Used Cars, Auto Sales Côte d'Ivoire",
                homePageTitleOg:
                    "AUTO24.ci - Certified Used Cars in Abidjan, Côte d'Ivoire | Buy & Sell Cars Locally",
                homePageDescOg:
                    "Looking for a certified used car in Abidjan, Côte d'Ivoire? AUTO24.ci is your go-to local marketplace for buying and selling quality used cars. Enjoy free evaluations, quick sales, and comprehensive certification.",
                homePageKeywordsOg:
                    "Certified Used Cars, Buy Used Cars Abidjan, Sell Used Cars Côte d'Ivoire, Car Marketplace, AUTO24 Côte d'Ivoire, Quality Used Cars, Auto Sales Côte d'Ivoire",
                buyPageTitle: 'Buy certified used cars in Abidjan - AUTO24.ci',
                buyPageDesc:
                    "Explore a wide range of certified used cars at AUTO24.ci. Simplifying your search for the ideal car that fits your budget and lifestyle in Côte d'Ivoire.",
                buyPageKeywords:
                    "Buy Cars Abidjan, Certified Used Cars, AUTO24 Côte d'Ivoire, Car Shopping, Affordable Cars Côte d'Ivoire",
                buyPageTitleOg:
                    'Buy certified used cars in Abidjan - AUTO24.ci',
                buyPageDescOg:
                    "Explore a wide range of certified used cars at AUTO24.ci. Simplifying your search for the ideal car that fits your budget and lifestyle in Côte d'Ivoire.",
                buyPageKeywordsOg:
                    "Buy Cars Abidjan, Certified Used Cars, AUTO24 Côte d'Ivoire, Car Shopping, Affordable Cars Côte d'Ivoire",
                sellPageTitle:
                    "Sell Your Car Fast & Conveniently in Abidjan, Côte d'Ivoire | AUTO24.ci",
                sellPageDesc:
                    'Looking to sell your car? AUTO24.ci offers a hassle-free way to do it, providing free evaluations and quick sales. Sell your used car locally in Abidjan today.',
                sellPageKeywords:
                    "Sell Car Abidjan, Used Car Sales Côte d'Ivoire, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Côte d'Ivoire",
                sellPageTitleOg:
                    "Sell Your Car Fast & Conveniently in Abidjan, Côte d'Ivoire | AUTO24.ci",
                sellPageDescOg:
                    'Looking to sell your car? AUTO24.ci offers a hassle-free way to do it, providing free evaluations and quick sales. Sell your used car locally in Abidjan today.',
                sellPageKeywordsOg:
                    "Sell Car Abidjan, Used Car Sales Côte d'Ivoire, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Côte d'Ivoire",
                aboutPageTitle:
                    "About AUTO24.ci - Your Trusted Local Used Car Marketplace in Abidjan, Côte d'Ivoire",
                aboutPageDesc:
                    "Learn more about AUTO24.ci , Côte d'Ivoire's premier certified used car marketplace. We offer quality cars and a seamless buying and selling experience for our local customers.",
                aboutPageKeywords:
                    "About AUTO24 Côte d'Ivoire, Certified Used Car Marketplace, Trusted Car Sales, Côte d'Ivoire Car Dealership",
                aboutPageTitleOg:
                    "About AUTO24.ci - Your Trusted Local Used Car Marketplace in Abidjan, Côte d'Ivoire",
                aboutPageDescOg:
                    "Learn more about AUTO24.ci , Côte d'Ivoire's premier certified used car marketplace. We offer quality cars and a seamless buying and selling experience for our local customers.",
                aboutPageKeywordsOg:
                    "About AUTO24 Côte d'Ivoire, Certified Used Car Marketplace, Trusted Car Sales, Côte d'Ivoire Car Dealership",
                financePageTitle:
                    "Easily Finance Your Used Car with AUTO24.ci in Abidjan, Côte d'Ivoire",
                financePageDesc:
                    'Discover flexible financing options for your used car in Abidjan. With AUTO24.ci , purchasing a car has never been easier.',
                financePageKeywords:
                    "Car Financing, Auto Credit, AUTO24 Côte d'Ivoire, Financing Options, Auto Loan",
                financePageTitleOg:
                    "Easily Finance Your Used Car with AUTO24.ci in Abidjan, Côte d'Ivoire",
                financePageDescOg:
                    'Discover flexible financing options for your used car in Abidjan. With AUTO24.ci , purchasing a car has never been easier.',
                financePageKeywordsOg:
                    "Car Financing, Auto Credit, AUTO24 Côte d'Ivoire, Financing Options, Auto Loan",
                insurePageTitle:
                    "Secure Reliable Car Insurance for Your Used Car with AUTO24.ci in Abidjan, Côte d'Ivoire",
                insurePageDesc:
                    'Get affordable, reliable car insurance for your used car with AUTO24.ci . Choose from a range of options to meet your needs.',
                insurePageKeywords:
                    "Car Insurance, Insurance Coverage, AUTO24 Côte d'Ivoire, Used Car Insurance, Insurance Options",
                insurePageTitleOg:
                    "Secure Reliable Car Insurance for Your Used Car with AUTO24.ci in Abidjan, Côte d'Ivoire",
                insurePageDescOg:
                    'Get affordable, reliable car insurance for your used car with AUTO24.ci . Choose from a range of options to meet your needs.',
                insurePageKeywordsOg:
                    "Car Insurance, Insurance Coverage, AUTO24 Côte d'Ivoire, Used Car Insurance, Insurance Options",
                blogPageTitle:
                    "AUTO24.ci Blog - Tips, Tricks, and Industry News in Abidjan, Côte d'Ivoire",
                blogPageDesc:
                    'Stay updated with the latest news, tips, and tricks in the auto market in Abidjan. The AUTO24.ci blog is your go-to resource for everything used cars.',
                blogPageKeywords:
                    "AUTO24 Blog, Auto News, Car Tips, Auto Market Côte d'Ivoire, Car Tricks",
                blogPageTitleOg:
                    "AUTO24.ci Blog - Tips, Tricks, and Industry News in Abidjan, Côte d'Ivoire",
                blogPageDescOg:
                    'Stay updated with the latest news, tips, and tricks in the auto market in Abidjan. The AUTO24.ci blog is your go-to resource for everything used cars.',
                blogPageKeywordsOg:
                    "AUTO24 Blog, Auto News, Car Tips, Auto Market Côte d'Ivoire, Car Tricks",
                qNaPageTitle: 'AUTO24 - Frequently asked questions ',
                contactUsPageTitle: ' AUTO24 - Contact us',
                privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
                termsNusePageTitle: 'AUTO24 - Terms & conditions',
                carDetailPageTitle: 'Buy {{make}},{{price}} FCFA - AUTO24',
                carDetailPageDesc:
                    'Buy a {{make}} certified used car at {{PRIX}} FCFA with AUTO24, Abidjan, Ivory Coast',
                buyPageDescWhenBrand:
                    'Best prices and services for {{BRAND}} used cars in Abidan, Ivory Coast. Refund policy, warranty, insurance, maintenance, and all included!'
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.coza:
            return {
                homePageTitle:
                    'AUTO24.co.za - Certified Used Cars in South Africa | Buy & Sell Cars Locally',
                homePageDesc:
                    'Looking for a certified used car in South Africa? AUTO24.co.za is your go-to local marketplace for buying and selling quality used cars.',
                homePageKeywords:
                    'Certified Used Cars, Buy Cars South Africa, Sell Cars South Africa, Car Marketplace, AUTO24 South Africa, Quality Used Cars',
                homePageTitleOg:
                    'AUTO24.co.za - Certified Used Cars in South Africa | Buy & Sell Cars Locally',
                homePageDescOg:
                    'Looking for a certified used car in South Africa? AUTO24.co.za is your go-to local marketplace for buying and selling quality used cars.',
                homePageKeywordsOg:
                    'Certified Used Cars, Buy Cars South Africa, Sell Cars South Africa, Car Marketplace, AUTO24 South Africa, Quality Used Cars',
                buyPageTitle:
                    'Buy certified used cars in South Africa - AUTO24.co.za',
                buyPageDesc:
                    'Explore a wide range of certified used cars at AUTO24.co.za. Simplifying your search for the ideal car that fits your budget and lifestyle.',
                buyPageKeywords:
                    'Buy Cars South Africa, Certified Used Cars, Quality Cars, Car Shopping, Affordable Cars',
                buyPageTitleOg:
                    'Buy certified used cars in South Africa - AUTO24.co.za',
                buyPageDescOg:
                    'Explore a wide range of certified used cars at AUTO24.co.za. Simplifying your search for the ideal car that fits your budget and lifestyle.',
                buyPageKeywordsOg:
                    'Buy Cars South Africa, Certified Used Cars, Quality Cars, Car Shopping, Affordable Cars',
                sellPageTitle:
                    'Sell Your Car Fast & Conveniently in South Africa | AUTO24.co.za',
                sellPageDesc:
                    'Looking to sell your car? We offer a hassle-free way to do it, providing free evaluations and quick sales. Sell your car locally today.',
                sellPageKeywords:
                    'Sell Cars South Africa, Used Car Sales, Quick Car Sales, Car Evaluation, Hassle-free Sales',
                sellPageTitleOg:
                    'Sell Your Car Fast & Conveniently in South Africa | AUTO24.co.za',
                sellPageDescOg:
                    'Looking to sell your car? We offer a hassle-free way to do it, providing free evaluations and quick sales. Sell your car locally today.',
                sellPageKeywordOg:
                    'Sell Cars South Africa, Used Car Sales, Quick Car Sales, Car Evaluation, Hassle-free Sales',
                aboutPageTitle:
                    'About AUTO24.co.za - Your Trusted Local Used Car Marketplace',
                aboutPageDesc:
                    "Learn more about AUTO24.co.za, South Africa's premier certified used car marketplace. Offering quality cars and a seamless buying and selling experience.",
                aboutPageKeywords:
                    'About AUTO24, Certified Used Car Marketplace, Trusted Car Sales, Local Car Dealership',
                aboutPageTitleOg:
                    'About AUTO24.co.za - Your Trusted Local Used Car Marketplace',
                aboutPageDescOg:
                    "Learn more about AUTO24.co.za, South Africa's premier certified used car marketplace. Offering quality cars and a seamless buying and selling experience.",
                aboutPageKeywordsOg:
                    'About AUTO24, Certified Used Car Marketplace, Trusted Car Sales, Local Car Dealership',
                financePageTitle: 'Finance Your Car Easily with AUTO24.co.za',
                financePageDesc:
                    'Secure the financing you need for your next car through AUTO24.co.za. Easy and straightforward car financing solutions.',
                financePageKeywords:
                    'Car Finance, AUTO24 Finance, Easy Financing, Car Loans South Africa',
                financePageTitleOg: 'Finance Your Car Easily with AUTO24.co.za',
                financePageDescOg:
                    'Secure the financing you need for your next car through AUTO24.co.za. Easy and straightforward car financing solutions.',
                financePageKeywordOg:
                    'Car Finance, AUTO24 Finance, Easy Financing, Car Loans South Africa',
                insurePageTitle:
                    'Insure Your Car with Confidence through AUTO24.co.za',
                insurePageDesc:
                    'Get your car insured with peace of mind. We offer a variety of insurance options to suit your needs.',
                insurePageKeywords:
                    'Car Insurance, AUTO24 Insurance, Vehicle Coverage, South Africa Insurance',
                insurePageTitleOg:
                    'Insure Your Car with Confidence through AUTO24.co.za',
                insurePageDescOg:
                    'Get your car insured with peace of mind. We offer a variety of insurance options to suit your needs.',
                insurePageKeywordOg:
                    'Car Insurance, AUTO24 Insurance, Vehicle Coverage, South Africa Insurance',
                blogPageTitle:
                    'AUTO24.co.za Blog - Insights & Tips on the South African Car Market',
                blogPageDesc:
                    'Stay updated with the latest trends, tips, and news in the South African car market.',
                blogPageKeywords:
                    'AUTO24 Blog, Car Tips, Car News, South African Car Market',
                blogPageTitleOg:
                    'AUTO24.co.za Blog - Insights & Tips on the South African Car Market',
                blogPageDescOg:
                    'Stay updated with the latest trends, tips, and news in the South African car market.',
                blogPageKeywordsOg:
                    'AUTO24 Blog, Car Tips, Car News, South African Car Market',
                qNaPageTitle: 'AUTO24 - Frequently asked questions ',
                contactUsPageTitle: ' AUTO24 - Contact us',
                privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
                termsNusePageTitle: 'AUTO24 - Terms & conditions',
                carDetailPageTitle: 'Buy {{make}},{{price}} R - AUTO24',
                carDetailPageDesc:
                    'Buy a {{make}} certified used car at {{PRIX}} R with AUTO24, South Africa',
                buyPageDescWhenBrand:
                    'Best prices and services for {{BRAND}} used cars in South Africa. Refund policy, warranty, insurance, maintenance, and all included!',
                buyCarTitle:
                    'Buy Certified Quality Used Cars in South Africa | AUTO24.co.za',
                buyCarDesc:
                    'Explore a wide range of certified used cars at AUTO24.co.za. Simplifying your search for the ideal car that fits your budget and lifestyle.'
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.rw:
            return {
                homePageTitle:
                    'AUTO24.rw - Certified Used Cars in Kigali, Rwanda | Buy & Sell Locally',
                homePageDesc:
                    'Your local marketplace in Kigali for buying and selling certified used cars. Experience free evaluations, fast transactions, and top-quality cars.',
                homePageKeywords:
                    'Certified Used Cars, Buy Cars Kigali, Sell Cars Rwanda, AUTO24 Rwanda, Quality Cars Kigali, Car Marketplace Rwanda',
                homePageTitleOg:
                    'AUTO24.rw - Certified Used Cars in Kigali, Rwanda | Buy & Sell Locally',
                homePageDescOg:
                    'Your local marketplace in Kigali for buying and selling certified used cars. Experience free evaluations, fast transactions, and top-quality cars.',
                homePageKeywordsOg:
                    'Certified Used Cars, Buy Cars Kigali, Sell Cars Rwanda, AUTO24 Rwanda, Quality Cars Kigali, Car Marketplace Rwanda',
                buyPageTitle:
                    'Buy Certified Quality Used Cars in Kigali | AUTO24.rw',
                buyPageDesc:
                    'Browse through a broad selection of certified used cars at AUTO24.rw . Simplifying your search for the perfect car in Kigali.',
                buyPageKeywords:
                    'Buy Cars Rwanda, Certified Used Cars, AUTO24 Rwanda, Car Shopping, Affordable Cars Kigali',
                buyPageTitleOg:
                    'Buy Certified Quality Used Cars in Kigali | AUTO24.rw',
                buyPageDescOg:
                    'Browse through a broad selection of certified used cars at AUTO24.rw . Simplifying your search for the perfect car in Kigali.',
                buyPageKeywordsOg:
                    'Buy Cars Rwanda, Certified Used Cars, AUTO24 Rwanda, Car Shopping, Affordable Cars Kigali',
                sellPageTitle: 'Sell Your Car Quickly in Kigali with AUTO24.rw',
                sellPageDesc:
                    'Looking to sell your car in Kigali? AUTO24.rw offers a hassle-free process with free evaluations and quick sales. Sell locally today.',
                sellPageKeywords:
                    'Sell Car Rwanda, Used Car Sales Kigali, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Kigali',
                sellPageTitleOg:
                    'Sell Your Car Quickly in Kigali with AUTO24.rw',
                sellPageDescOg:
                    'Looking to sell your car in Kigali? AUTO24.rw offers a hassle-free process with free evaluations and quick sales. Sell locally today.',
                sellPageKeywordsOg:
                    'Sell Car Rwanda, Used Car Sales Kigali, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Kigali',
                aboutPageTitle:
                    'About AUTO24.rw - Your Trusted Local Used Car Marketplace in Kigali',
                aboutPageDesc:
                    "Learn about AUTO24.rw , Kigali's leading certified used car marketplace. We offer quality cars and a seamless buying and selling experience.",
                aboutPageKeywords:
                    'About AUTO24 Rwanda, Certified Used Car Marketplace, Trusted Car Sales, Rwanda Car Dealership',
                aboutPageTitleOg:
                    'About AUTO24.rw - Your Trusted Local Used Car Marketplace in Kigali',
                aboutPageDescOg:
                    "Learn about AUTO24.rw , Kigali's leading certified used car marketplace. We offer quality cars and a seamless buying and selling experience.",
                aboutPageKeywordsOg:
                    'About AUTO24 Rwanda, Certified Used Car Marketplace, Trusted Car Sales, Rwanda Car Dealership',
                financePageTitle:
                    'Finance Your Car Purchase Easily with AUTO24.rw',
                financePageDesc:
                    'Access affordable and reliable car financing options through AUTO24.rw . Drive your dream car home today!',
                financePageKeywords:
                    'Car Financing Rwanda, AUTO24 Finance, Affordable Car Loans, Kigali Car Financing',
                financePageTitleOg:
                    'Finance Your Car Purchase Easily with AUTO24.rw',
                financePageDescOg:
                    'Access affordable and reliable car financing options through AUTO24.rw . Drive your dream car home today!',
                financePageKeywordsOg:
                    'Car Financing Rwanda, AUTO24 Finance, Affordable Car Loans, Kigali Car Financing',
                insurePageTitle: 'Insure Your Car Securely with AUTO24.rw',
                insurePageDesc:
                    'Find comprehensive and affordable car insurance solutions through AUTO24.rw in Kigali, Rwanda.',
                insurePageKeywords:
                    'Car Insurance Rwanda, AUTO24 Insurance, Vehicle Insurance Kigali, Affordable Insurance',
                insurePageTitleOg: 'Insure Your Car Securely with AUTO24.rw',
                insurePageDescOg:
                    'Find comprehensive and affordable car insurance solutions through AUTO24.rw in Kigali, Rwanda.',
                insurePageKeywordsOg:
                    'Car Insurance Rwanda, AUTO24 Insurance, Vehicle Insurance Kigali, Affordable Insurance',
                blogPageTitle:
                    'AUTO24.rw Blog - Expert Tips and Insights on Used Cars in Rwanda',
                blogPageDesc:
                    'Discover expert tips, guides, and the latest news about the used car market in Kigali and Rwanda.',
                blogPageKeywords:
                    'AUTO24 Blog, Car Tips Rwanda, Car News Rwanda, Used Car Insights, Rwanda Car Market',
                blogPageTitleOg:
                    'AUTO24.rw Blog - Expert Tips and Insights on Used Cars in Rwanda',
                blogPageDescOg:
                    'Discover expert tips, guides, and the latest news about the used car market in Kigali and Rwanda.',
                blogPageKeywordsOg:
                    'AUTO24 Blog, Car Tips Rwanda, Car News Rwanda, Used Car Insights, Rwanda Car Market',
                qNaPageTitle: 'AUTO24 - Frequently asked questions ',
                contactUsPageTitle: ' AUTO24 - Contact us',
                privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
                termsNusePageTitle: 'AUTO24 - Terms & conditions',
                carDetailPageTitle: 'Buy {{make}},{{price}} RWF - AUTO24',
                carDetailPageDesc:
                    'Buy a {{make}} certified used car at {{PRIX}} RWF with AUTO24, Rwanda',
                buyPageDescWhenBrand:
                    'Best prices and services for {{BRAND}} used cars in Rwanda. Refund policy, warranty, insurance, maintenance, and all included!'
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.sn:
            return {
                homePageTitle:
                    "Voitures d'Occasion Certifiées à Dakar, Sénégal | Achetez et Vendez Localement",
                homePageDesc:
                    `Vous cherchez une voiture d'occasion certifiée à Dakar, Sénégal? AUTO24.sn est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Nous offrons des évaluations gratuites, des ventes rapides et une certification complète.`,
                homePageKeywords: 
                    `Voitures d'Occasion Certifiées, Acheter Voiture Dakar, Vendre Voiture Sénégal, Plateforme Automobile, AUTO24 Sénégal, Voitures de Qualité, Vente Auto Sénégal`,
                homePageTitleOg:
                    "Voitures d'Occasion Certifiées à Dakar, Sénégal | Achetez et Vendez Localement",
                homePageDescOg:
                    `Vous cherchez une voiture d'occasion certifiée à Dakar, Sénégal? AUTO24.sn est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Nous offrons des évaluations gratuites, des ventes rapides et une certification complète.`,
                homePageKeywordsOg: 
                    `Voitures d'Occasion Certifiées, Acheter Voiture Dakar, Vendre Voiture Sénégal, Plateforme Automobile, AUTO24 Sénégal, Voitures de Qualité, Vente Auto Sénégal`,
                sellPageTitle: "Sell your car quickly in Senegal - AUTO24",
                sellPageDesc: "Sell your car easily, safely and quickly in Dakar. We send our inspectors to your home, give you a price in 30 minutes and pay you instantly!",
                sellPageTitleOg: "Sell your car quickly in Senegal - AUTO24",
                sellPageDescOg: "Sell your car easily, safely and quickly in Dakar. We send our inspectors to your home, give you a price in 30 minutes and pay you instantly!",
                buyPageTitle: "Buy certified used cars in Senegal - AUTO24",
                buyPageDesc: "Find the best prices for used cars to buy in Dakar, Senegal, with 100 points inspection, 5 days refund policy, 6 months warranty and more.",
                buyPageTitleOg: "Buy certified used cars in Senegal - AUTO24",
                buyPageDescOg: "Find the best prices for used cars to buy in Dakar, Senegal, with 100 points inspection, 5 days refund policy, 6 months warranty and more.",
                financePageTitle: "Finance your car in Senegal - AUTO24",
                financePageDesc: "Get the best financing offers for your used cars with our network of partners in Dakar, Senegal. Apply and get in touch with our agents quickly.",
                financePageTitleOg: "Finance your car in Senegal - AUTO24",
                financePageDescOg: "Get the best financing offers for your used cars with our network of partners in Dakar, Senegal. Apply and get in touch with our agents quickly.",
                aboutPageTitle: " AUTO24, a new way to buy and sell cars in Senegal",
                aboutPageDesc: " AUTO24, a new way to buy and sell cars in Senegal",
                aboutPageTitleOg: " AUTO24, a new way to buy and sell cars in Senegal",
                aboutPageDescOg: " AUTO24, a new way to buy and sell cars in Senegal",
                blogPageTitle: " Automotive news in Senegal with AUTO24",
                blogPageDesc: "Maintenance advice and all the automotive industry news in Senegal with AUTO24.",
                blogPageTitleOg: " Automotive news in Senegal with AUTO24",
                blogPageDescOg: "Maintenance advice and all the automotive industry news in Senegal with AUTO24.",
                qNaPageTitle: "AUTO24 - Frequently asked questions ",
                contactUsPageTitle: " AUTO24 - Contact us",
                privacyPolicyPageTitle: " AUTO24 - Privacy policy",
                termsAndusePageTitle: "AUTO24 - Terms & conditions",
                insurePageTitle: "Insure your car in Senegal - AUTO24",
                insurePageDesc: "Get the best insurance offers for your used cars with our network of partners in Dakar, Senegal. Apply and get in touch with our agents quickly.",
                insurePageTitleOg: "Insure your car in Senegal - AUTO24",
                insurePageDescOg: "Get the best insurance offers for your used cars with our network of partners in Dakar, Senegal. Apply and get in touch with our agents quickly.",
                carDetailPageTitle: "Buy {{make}},{{price}} FCFA - AUTO24",
                carDetailPageDesc: "Buy a {{make}} certified used car at {{PRIX}} FCFA with AUTO24, Dakar, Senegal",
                buyPageDescWhenBrand: "Best prices and services for {{BRAND}} used cars in Dakar, Senegal. Refund policy, warranty, insurance, maintenance, and all included!"
            };
        default:
            return {
                homePageTitle:
                    'AUTO24.ma - Certified Used Cars in Morocco | Buy & Sell Cars Nationwide',
                homePageDesc:
                    'Your go-to marketplace in Morocco for buying and selling certified used cars. Enjoy free evaluations, quick sales, and quality assurance across the nation.',
                homePageKeywords:
                    'Certified Used Cars, Buy Used Cars Morocco, Sell Used Cars Morocco, Car Marketplace, AUTO24 Morocco, Quality Used Cars, Auto Sales Morocco',
                homePageTitleOg:
                    'AUTO24.ma - Certified Used Cars in Morocco | Buy & Sell Cars Nationwide',
                homePageDescOg:
                    'Your go-to marketplace in Morocco for buying and selling certified used cars. Enjoy free evaluations, quick sales, and quality assurance across the nation.',
                homePageKeywordsOg:
                    'Certified Used Cars, Buy Used Cars Morocco, Sell Used Cars Morocco, Car Marketplace, AUTO24 Morocco, Quality Used Cars, Auto Sales Morocco',
                buyPageTitle:
                    'Buy Certified Quality Used Cars in Morocco | AUTO24.ma',
                buyPageDesc:
                    'Explore a wide selection of certified used cars at AUTO24.ma . We simplify your search for the perfect car that fits your budget and lifestyle across Morocco.',
                buyPageKeywords:
                    'Buy Cars Morocco, Certified Used Cars, AUTO24 Morocco, Car Shopping, Affordable Cars Morocco',
                buyPageTitleOg:
                    'Buy Certified Quality Used Cars in Morocco | AUTO24.ma',
                buyPageDescOg:
                    'Explore a wide selection of certified used cars at AUTO24.ma . We simplify your search for the perfect car that fits your budget and lifestyle across Morocco.',
                buyPageKeywordsOg:
                    'Buy Cars Morocco, Certified Used Cars, AUTO24 Morocco, Car Shopping, Affordable Cars Morocco',
                sellPageTitle:
                    'Sell Your Car Fast & Conveniently in Morocco | AUTO24.ma',
                sellPageDesc:
                    'Looking to sell your car? AUTO24.ma offers a hassle-free way to do it, providing free evaluations and quick sales nationwide in Morocco.',
                sellPageKeywords:
                    'Sell Car Morocco, Used Car Sales Morocco, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Morocco',
                sellPageTitleOg:
                    'Sell Your Car Fast & Conveniently in Morocco | AUTO24.ma',
                sellPageDescOg:
                    'Looking to sell your car? AUTO24.ma offers a hassle-free way to do it, providing free evaluations and quick sales nationwide in Morocco.',
                sellPageKeywordsOg:
                    'Sell Car Morocco, Used Car Sales Morocco, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Morocco',
                aboutPageTitle:
                    'About AUTO24.ma - Your Trusted Nationwide Used Car Marketplace in Morocco',
                aboutPageDesc:
                    "Learn about AUTO24.ma , Morocco's premier certified used car marketplace. Offering quality cars and a seamless buying and selling experience across the country.",
                aboutPageKeywords:
                    'About AUTO24 Morocco, Certified Used Car Marketplace, Trusted Car Sales, Morocco Car Dealership',
                aboutPageTitleOg:
                    'About AUTO24.ma - Your Trusted Nationwide Used Car Marketplace in Morocco',
                aboutPageDescOg:
                    "Learn about AUTO24.ma , Morocco's premier certified used car marketplace. Offering quality cars and a seamless buying and selling experience across the country.",
                aboutPageKeywordsOg:
                    'About AUTO24 Morocco, Certified Used Car Marketplace, Trusted Car Sales, Morocco Car Dealership',
                financePageTitle:
                    'Easily Finance Your Used Car with AUTO24.ma in Morocco',
                financePageDesc:
                    'Discover flexible financing options for your used car with AUTO24.ma . Simplify car purchasing across Morocco.',
                financePageKeywords:
                    'Car Financing, Auto Credit, AUTO24 Morocco, Financing Options, Auto Loan',
                financePageTitleOg:
                    'Easily Finance Your Used Car with AUTO24.ma in Morocco',
                financePageDescOg:
                    'Discover flexible financing options for your used car with AUTO24.ma . Simplify car purchasing across Morocco.',
                financePageKeywordsOg:
                    'Car Financing, Auto Credit, AUTO24 Morocco, Financing Options, Auto Loan',
                insurePageTitle:
                    'Secure Reliable Car Insurance with AUTO24.ma in Morocco',
                insurePageDesc:
                    'Obtain affordable, reliable car insurance for your used car with AUTO24.ma . Choose from a range of options to meet your needs nationwide.',
                insurePageKeywords:
                    'Car Insurance, Insurance Coverage, AUTO24 Morocco, Used Car Insurance, Insurance Options',
                insurePageTitleOg:
                    'Secure Reliable Car Insurance with AUTO24.ma in Morocco',
                insurePageDescOg:
                    'Obtain affordable, reliable car insurance for your used car with AUTO24.ma . Choose from a range of options to meet your needs nationwide.',
                insurePageKeywordsOg:
                    'Car Insurance, Insurance Coverage, AUTO24 Morocco, Used Car Insurance, Insurance Options',
                blogPageTitle:
                    'AUTO24.ma Blog - Tips, Tricks, and Industry News in Morocco',
                blogPageDesc:
                    'Stay updated with the latest news, tips, and tricks in the Moroccan auto market. AUTO24.ma is your go-to resource for everything used cars.',
                blogPageKeywords:
                    'AUTO24 Blog, Auto News, Car Tips, Moroccan Auto Market, Car Tricks',
                blogPageTitleOg:
                    'AUTO24.ma Blog - Tips, Tricks, and Industry News in Morocco',
                blogPageDescOg:
                    'Stay updated with the latest news, tips, and tricks in the Moroccan auto market. AUTO24.ma is your go-to resource for everything used cars.',
                blogPageKeywordsOg:
                    'AUTO24 Blog, Auto News, Car Tips, Moroccan Auto Market, Car Tricks',
                qNaPageTitle: 'AUTO24 - Frequently asked questions ',
                contactUsPageTitle: ' AUTO24 - Contact us',
                privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
                termsNusePageTitle: 'AUTO24 - Terms & conditions',
                carDetailPageTitle: 'Buy {{make}},{{price}} DH - AUTO24',
                carDetailPageDesc:
                    'Buy a {{make}} certified used car at {{PRIX}} DH with AUTO24',
                buyPageDescWhenBrand:
                    'Best prices and services for {{BRAND}} used cars. Refund policy, warranty, insurance, maintenance, and all included!'
            };
    }
};

export default getMetaEnglish;
